// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Textdetector from "../../blocks/textdetector/src/Textdetector";
import Cfchatgptintegration6 from "../../blocks/cfchatgptintegration6/src/Cfchatgptintegration6";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Videos from "../../blocks/videos/src/Videos";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Cfaibasedrecommendations from "../../blocks/cfaibasedrecommendations/src/Cfaibasedrecommendations";
import Cfwhiteboardaugmentation from "../../blocks/cfwhiteboardaugmentation/src/Cfwhiteboardaugmentation";
import WhiteboardCollaboration from "../../blocks/whiteboardcollaboration/src/WhiteboardCollaboration";
import QuestionBank from "../../blocks/questionbank/src/QuestionBank";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Cfscaffoldingofquestionsprompts from "../../blocks/cfscaffoldingofquestionsprompts/src/Cfscaffoldingofquestionsprompts";
import Cfparsingofinformationfromwhiteboardocr from "../../blocks/cfparsingofinformationfromwhiteboardocr/src/Cfparsingofinformationfromwhiteboardocr";
import Cfsyncingfunctionality from "../../blocks/cfsyncingfunctionality/src/Cfsyncingfunctionality";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";



const routeMap = {
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Textdetector:{
 component:Textdetector,
path:"/Textdetector"},
Cfchatgptintegration6:{
 component:Cfchatgptintegration6,
path:"/Cfchatgptintegration6"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Videos:{
 component:Videos,
path:"/Videos"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Cfaibasedrecommendations:{
 component:Cfaibasedrecommendations,
path:"/Cfaibasedrecommendations"},
Cfwhiteboardaugmentation:{
 component:Cfwhiteboardaugmentation,
path:"/Cfwhiteboardaugmentation"},
WhiteboardCollaboration:{
 component:WhiteboardCollaboration,
path:"/WhiteboardCollaboration"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Cfscaffoldingofquestionsprompts:{
 component:Cfscaffoldingofquestionsprompts,
path:"/Cfscaffoldingofquestionsprompts"},
Cfparsingofinformationfromwhiteboardocr:{
 component:Cfparsingofinformationfromwhiteboardocr,
path:"/Cfparsingofinformationfromwhiteboardocr"},
Cfsyncingfunctionality:{
 component:Cfsyncingfunctionality,
path:"/Cfsyncingfunctionality"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <BuilderProvider>
        <View style={{ height: '100vh', width: '100vw' }}>
          <TopNav />
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;